import React from 'react';
import ScreenComponent from './ScreenComponent';
import { Confirmation } from '../Confirmation';
import axios from 'axios';
import { config } from '../../app/config';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../actions';
import { NotesList } from "./note/NotesList";
import '../../styles/elements/screen.scss';
import '../../styles/elements/screens/note.scss';
import TranslationService from "../../app/services/TranslationService";


class NoteClass extends ScreenComponent
{
    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            notes: [],
            note_id: '',
            content: '',
            delete_note: null
        };
    };

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'note';
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.loadNotes();
        }
    };

    /**
     * @param event
     */
    handleContentChange = (event) => {
        this.setState({content: event.target.value});
    };

    /**
     * @returns {{height: string}}
     */
    getTextareaStyle = () => {
        return {
            height: '50vh'
        };
    };

    /**
     * Load all notes
     */
    loadNotes = () => {
        this.props.preloaderShow();
        let that = this;

        axios.get(config.apiAddress + '/app/note/list')
            .then(function (response) {
                that.setState({notes: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
            that.props.alert('Nie udało się załadować notatek');
        });
    };

    /**
     * @param noteId
     */
    openNote = (noteId) => {
        let that = this;
        if (noteId !== null) {
            this.props.preloaderShow();

            axios.get(config.apiAddress + '/app/note/' + noteId)
                .then(function (response) {
                    that.setState({
                        note_id: noteId,
                        content: response.data.note
                    });
                    that.props.preloaderHide();
                }).catch(function(error) {
                that.setState({
                    note_id: '',
                    content: ''
                });
                that.props.preloaderHide();
                that.props.alert('Nie udało się otworzyć notatki');
            });
        }
    };

    /**
     * Cancel button
     */
    cancel = () => {
        this.setState({note_id: '', content: ''});
    };

    /**
     * Save note
     */
    save = () => {
        let that = this;
        this.props.preloaderShow();

        if (this.state.note_id === '') {
            axios.post(config.apiAddress + '/app/note', {content: that.state.content})
                .then(function (response) {
                    that.cancel();
                    that.loadNotes();
                    that.props.alert('Notatka została zapisana');
                }).catch(function(error) {
                that.props.alert('Nie udało się zapisać notatki');
                that.props.preloaderHide();
            });
        } else {
            axios.put(config.apiAddress + '/app/note/' + that.state.note_id, {content: that.state.content})
                .then(function (response) {
                    that.cancel();
                    that.loadNotes();
                    that.props.alert('Notatka została zapisana');
                }).catch(function(error) {
                that.props.alert('Nie udało się zapisać notatki');
                that.props.preloaderHide();
            });
        }
    };

    /**
     * @returns {*}
     */
    getButtonsStyle = () => {
        if (this.state.note_id !== '') {
            return {display: 'inline-block'};
        }

        return {display: 'none'};
    };

    /**
     * Delete note
     */
    deleteNote = () => {
        if (this.state.note_id === '') {
            return;
        }

        this.setState({delete_note: config.apiAddress + '/app/note/' + this.state.note_id});
    };

    /**
     * Action on delete success
     */
    deleteNoteSuccess = (response, address) => {
        this.cancel();
        this.loadNotes();
        this.props.alert('Notatka została usunięta');
    };

    /**
     * Action on delete error
     */
    deleteNoteError = (error, address) => {
        this.props.alert('Nie udało się usunąć notatki');
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className="screen screen-note" style={this.getStyle()}>
                <div className="content imd-text-accent">
                    <div className="imd-container-fluid">
                        <div className="imd-row">
                            <div className="imd-col-lg-3 imd-col-md-4 imd-col-xs-12">
                                <NotesList
                                    className="imd-hide-sm imd-hide-xl"
                                    elements={this.state.notes}
                                    openedElementId={this.state.note_id}
                                    openElement={this.openNote}
                                />
                            </div>

                            <div className="imd-col-lg-9 imd-col-md-8 imd-col-xs-12">
                                <div className="actions">
                                    <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={this.save}>
                                        {TranslationService.trans(this.props.language, 'common.save')}
                                    </button>

                                    <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={this.cancel} style={this.getButtonsStyle()}>
                                        {TranslationService.trans(this.props.language, 'common.cancel')}
                                    </button>

                                    <button className="imd-button imd-button-outline imd-border-color-red imd-text-red" onClick={this.deleteNote} style={this.getButtonsStyle()}>
                                        {TranslationService.trans(this.props.language, 'common.delete')}
                                    </button>
                                </div>

                                <hr className="imd-border-color-140-accent" />

                                <textarea className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent" placeholder={TranslationService.trans(this.props.language, 'common.content')} value={this.state.content} style={this.getTextareaStyle()} onChange={this.handleContentChange}/>
                            </div>
                        </div>
                    </div>

                    <Confirmation
                        address={this.state.delete_note}
                        onSuccess={this.deleteNoteSuccess}
                        onError={this.deleteNoteError}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        view: state.view,
        language: TranslationService.getLanguage(state.user)
    }
};

export const NoteScreen = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(NoteClass);
