export const pl_pl = {
    "_code": "pl",
    "_name": "Polski",
    "_direction": "ltr",
    "_alphabet": "latin",
    "_welcome": "Witam %message",
    "_welcome_name_process_function": "getPlPl",
    "_national_days": "05-01,05-02,05-03,11-11,08-15,2023-10-15",
    "_months": [
        {id: 1, name: 'Styczeń'},
        {id: 2, name: 'Luty'},
        {id: 3, name: 'Marzec'},
        {id: 4, name: 'Kwiecień'},
        {id: 5, name: 'Maj'},
        {id: 6, name: 'Czerwiec'},
        {id: 7, name: 'Lipiec'},
        {id: 8, name: 'Sierpień'},
        {id: 9, name: 'Wrzesień'},
        {id: 10, name: 'Październik'},
        {id: 11, name: 'Listopad'},
        {id: 12, name: 'Grudzień'}
    ],
    "_date_format": '%d.%m.%Y',
    "common": {
        "save": "Zapisz",
        "cancel": "Anuluj",
        "delete": "Usuń",
        "ok": "OK",
        "content": "Treść",
        "close": "Zamknij",
        "name": "Nazwa",
        "url": "Adres URL",
        "add": "Dodaj",
        "yes": "Tak",
        "no": "Nie",
        "edit": "Edytuj",
        "back": "Wróć",
        "actions": {
            "save_failed": "Nie udało się zapisać zmian",
            "failed_to_load_data": "Nie udało się załadować danych",
            "are_you_sure_to_delete_this_item": "Czy na pewno chcesz usunąć ten element?"
        }
    },
    "login": {
        "imid_button": "Zaloguj się z IMid",
        "license": {
            "name": "Licencja oprogramowania",
            "text": "Logując się akceptujesz",
            "button": "warunki licencji"
        },
        "actions": {
            "failed": "Nie udało się zalogować",
            "relogin_required": "Wymagane ponowne logowanie",
            "not_allowed": "Nie masz dostępu do tej wersji aplikacji."
        }
    },
    "main": {
        "how_can_i_help_you": "W czym mogę pomóc?",
        "image_answer": "Odpowiedź graficzna",
        "recording_error": "Przepraszam, ale nie zrozumiałam.",
        "actions": {
            "query_failed": "Przepraszam, ale nie udało mi się znaleźć odpowiedzi."
        }
    },
    "menu": {
        "home": "Ekran główny",
        "note": "Notatki",
        "calendars": "Kalendarze",
        "finance": "Finanse",
        "recipes": "Przepisy",
        "integrations": "Integracje",
        "settings": "Ustawienia",
        "notifications": {
            "empty": "Brak powiadomień"
        },
        "imid": {
            "authorization": "Autoryzacja IMid",
            "message": "Autoryzowano logowanie IMid"
        }
    },
    "settings": {
        "tabs": {
            "account": "Konto",
            "devices": "Urządzenia",
            "app": "Aplikacja",
            "license": "Licencja"
        },
        "account": {
            "full_name": "Imię i nazwisko",
            "email_address": "Adres e-mail",
            "logout": "Wyloguj",
            "andra": {
                "account": "Konto Andry",
                "device": "Urządzenie",
                "device_name": "Nazwa urządzenia"
            },
            "actions": {
                "logout": "Nastąpiło poprawne wylogowanie z aplikacji"
            }
        },
        "devices": {
            "connected_devices": "Połączone urządzenia",
            "device_name": "Nazwa urządzenia",
            "device_id": "Identyfikator",
            "options": "Opcje",
            "change_name": "Zmień nazwę",
            "actions": {
                "device_removed": "Urządzenie zostało usunięte",
                "device_remove_failed": "Nie udało się usunąć urządzenia",
                "device_saved": "Zapisano zmiany"
            }
        },
        "app": {
            "settings": "Ustawienia aplikacji",
            "enable_voice": "Używaj odpowiedzi głosowych",
            "disable_voice": "Wyłącz odpowiedzi głosowe",
            "system_info": {
                "title": "Informacje systemowe",
                "version": "Wersja",
                "build": "Kompilacja"
            }
        },
        "language": {
            "actions": {
                "success": "Ustawienia języka zostały zapisane",
                "failed": "Nie udało się zmienić języka",
            }
        }
    },
    "nyumba": {
        "work_mode": {
            "unknown": "NIEZNANY",
            "home": "W domu",
            "away": "Poza domem",
            "manual": "Manual",
            "silence": "Cichy"
        },
        "headers": {
            "scenes": "Sceny",
            "devices": "Urządzenia"
        },
        "alarm": {
            "enter_code": "Podaj kod alarmu",
            "state": {
                "active": "Aktywny",
                "waked_up": "Wzbudzony",
                "run": "Uruchomiony",
                "sleeping": "Trwa usypianie...",
                "disabling": "Trwa dezaktywowanie...",
                "inactive": "Nieaktywny"
            },
            "actions": {
                "activate": "Przesłano polecenie aktywacji alarmu",
                "activation_failed": "Nie udało się aktywować alarmu",
                "send_command": "Przesłano polecenie do alarmu",
                "send_command_failed": "Nie udało się przesłać polecenia"
            }
        },
        "devices": {
            "unknown_devices": "Nieprzypisane urządzenia"
        },
        "actions": {
            "send_command": "Polecenie zostało przesłane",
            "data_failed": "Nie udało się załadować danych",
            "send_failed": "Nie udało się przesłać danych"
        }
    },
    "finance": {
        "balance": "Saldo",
        "budget": "Wykorzystanie budżetu",
        "default_categories": {
            "flat": "Mieszkanie",
            "bills": "Rachunki",
            "food": "Jedzenie",
            "party": "Rozrywka",
            "other": "Inne"
        },
        "actions": {
            "saved": "Zapisano zmiany",
            "failed": "Nie udało się zapisać zmian",
            "nan": "Nieprawidłowa wartość"
        }
    },
    "calendars": {
        "data_loading_error": "Nie udało się załadować danych",
        "empty_day": "Brak wydarzeń w tym dniu",
        "subscribed_calendars": {
            "title": "Subskrybowane kalendarze",
            "add_new": "Dodaj nowy kalendarz",
            "added": "Kalendarz został zasubskrybowany",
            "removed": {
                "success": "Kalendarz został usunięty",
                "error": "Nie udało się usunąć kalendarza"
            }
        },
        "event": {
            "date": "Data",
            "time": "Czas",
            "place": "Miejsce",
            "participants": "Uczestnicy",
            "details": "Dodatkowe informacje"
        }
    },
    "integrations": {
        "webhooks": {
            "title": "Webhooki",
            "name": "Nazwa wyzwalacza",
            "actions": "Akcje",
            "new_action": "Nowa akcja"
        }
    },
    "recipes": {
        "new_recipe": "Nowy przepis",
        "ingredients": "Składniki",
        "instructions": "Przygotowanie",
        "access": {
            "private": "Przepis prywatny",
            "public": "Przepis publiczny"
        }
    }
}
