import { config } from "../config";

export default class SystemInfoService {
    static getVersion() {
        return config.version;
    }

    static getBuild() {
        return config.build;
    }
}
