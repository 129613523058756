export const uk_ua = {
    "_code": "uk",
    "_name": "Українська",
    "_direction": "ltr",
    "_alphabet": "cyrillic",
    "_welcome": "Привіт %message",
    "_welcome_name_process_function": "getUkUa",
    "_national_days": "06-28,08-24,10-14",
    "_months": [
        { "id": "1", "name": "Січня" },
        { "id": "2", "name": "Лютий" },
        { "id": "3", "name": "Березень" },
        { "id": "4", "name": "Квітень" },
        { "id": "5", "name": "Травня" },
        { "id": "6", "name": "Червень" },
        { "id": "7", "name": "Липень" },
        { "id": "8", "name": "Серпень" },
        { "id": "9", "name": "Вересень" },
        { "id": "10", "name": "Жовтень" },
        { "id": "11", "name": "Листопад" },
        { "id": "12", "name": "Грудень" }
    ],
    "_date_format": "%d.%m.%Y",
    "common": {
        "save": "Зберегти",
        "cancel": "Скасувати",
        "delete": "Видалити",
        "ok": "Добре",
        "content": "Зміст",
        "close": "Закрити",
        "name": "Ім\u0027я",
        "url": "URL-адреса",
        "add": "Додати",
        "yes": "Так",
        "no": "НІ",
        "edit": "Редагувати",
        "back": "Повертайся",
        "actions": {
            "save_failed": "Не вдалося зберегти зміни",
            "failed_to_load_data": "Не вдалося завантажити дані",
            "are_you_sure_to_delete_this_item": "Ви впевнені, що хочете видалити цей елемент?"
        }
    },
    "login": {
        "imid_button": "Увійдіть через IMid",
        "license": {
            "name": "Ліцензія на програмне забезпечення",
            "text": "Увійшовши в систему, ви приймаєте",
            "button": "умови ліцензії"
        },
        "actions": {
            "failed": "Не вдалося увійти",
            "relogin_required": "Потрібен повторний вхід",
            "not_allowed": "Ви не маєте доступу до цієї версії програми."
        }
    },
    "main": {
        "how_can_i_help_you": "Чим я можу допомогти?",
        "image_answer": "Графічна відповідь",
        "recording_error": "Вибачте, я не зрозумів.",
        "actions": {
            "query_failed": "Вибачте, я не зміг знайти відповідь."
        }
    },
    "menu": {
        "home": "Головний екран",
        "note": "Примітки",
        "calendars": "Календарі",
        "finance": "Фінанси",
        "recipes": "Рецепти",
        "integrations": "Інтеграції",
        "settings": "Налаштування",
        "notifications": {
            "empty": "Немає повідомлень"
        },
        "imid": {
            "authorization": "Авторизація IMid",
            "message": "Авторизовано вхід в систему IMid"
        }
    },
    "settings": {
        "tabs": {
            "account": "Обліковий запис",
            "devices": "Пристрої",
            "app": "Додаток",
            "license": "Ліцензія"
        },
        "account": {
            "full_name": "Ім\u0027я та прізвище",
            "email_address": "Адреса електронної пошти",
            "logout": "Вийти",
            "andra": {
                "account": "Акаунт Андра",
                "device": "Пристрій",
                "device_name": "Назва пристрою"
            },
            "actions": {
                "logout": "Ви успішно вийшли з програми"
            }
        },
        "devices": {
            "connected_devices": "Підключені пристрої",
            "device_name": "Назва пристрою",
            "device_id": "ID",
            "options": "Опції",
            "change_name": "Змінити назву",
            "actions": {
                "device_removed": "Пристрій видалено",
                "device_remove_failed": "Не вдалося видалити пристрій",
                "device_saved": "Зміни збережено"
            }
        },
        "app": {
            "settings": "Налаштування програми",
            "enable_voice": "Використовуйте голосові відповіді",
            "disable_voice": "Вимкніть голосові відповіді",
            "system_info": {
                "title": "Інформація про систему",
                "version": "Версія",
                "build": "Складання"
            }
        },
        "language": {
            "actions": {
                "success": "Налаштування мови збережено",
                "failed": "Не вдалося змінити мову"
            }
        }
    },
    "nyumba": {
        "work_mode": {
            "unknown": "НЕВІДОМО",
            "home": "Вдома",
            "away": "Поза будинком",
            "manual": "Інструкція",
            "silence": "Тихо"
        },
        "headers": {
            "scenes": "Сцени",
            "devices": "Пристрої"
        },
        "alarm": {
            "enter_code": "Введіть код будильника",
            "state": {
                "active": "Активний",
                "waked_up": "Схвильований",
                "run": "Запущено",
                "sleeping": "Триває сон...",
                "disabling": "Триває деактивація...",
                "inactive": "Неактивний"
            },
            "actions": {
                "activate": "Надіслано команду активації сигналізації",
                "activation_failed": "Не вдалося активувати сигналізацію",
                "send_command": "Надана команда на сигналізацію",
                "send_command_failed": "Не вдалося надіслати команду"
            }
        },
        "devices": {
            "unknown_devices": "Непризначені пристрої"
        },
        "actions": {
            "send_command": "Команда надіслана",
            "data_failed": "Не вдалося завантажити дані",
            "send_failed": "Не вдалося передати дані"
        }
    },
    "finance": {
        "balance": "Баланс",
        "budget": "Використання бюджету",
        "default_categories": {
            "flat": "Квартира",
            "bills": "Рахунки",
            "food": "Харчування",
            "party": "Розваги",
            "other": "Інше"
        },
        "actions": {
            "saved": "Зміни збережено",
            "failed": "Не вдалося зберегти зміни",
            "nan": "Недійсне значення"
        }
    },
    "calendars": {
        "data_loading_error": "Не вдалося завантажити дані",
        "empty_day": "Цього дня немає подій",
        "subscribed_calendars": {
            "title": "Підписані календарі",
            "add_new": "Додайте новий календар",
            "added": "На календар підписані",
            "removed": {
                "success": "Календар видалено",
                "error": "Не вдалося видалити календар"
            }
        },
        "event": {
            "date": "Дата",
            "time": "Час",
            "place": "Місце",
            "participants": "Учасники",
            "details": "Додаткова інформація"
        }
    },
    "integrations": {
        "webhooks": {
            "title": "Веб-хуки",
            "name": "Назва тригера",
            "actions": "Акції",
            "new_action": "Нова акція"
        }
    },
    "recipes": {
        "new_recipe": "Новий рецепт",
        "ingredients": "Інгредієнти",
        "instructions": "Інструкції",
        "access": {
            "private": "Приватний доступ",
            "public": "Загальний доступ"
        }
    }
}
